import React from "react";
import "./Widget.css";
export default function Widget() {
  return (
    <div>
      <div id="mySidenav" class="sidenav">
        <a href="tel:+91 9972963229" id="call">
          <i class="fas fa-phone-alt me-2"></i>&nbsp;&nbsp;9972963229
        </a>
        <a href='https://api.whatsapp.com/send?phone=+919342161305'  id="bookapp">
        <i class="fab fa-whatsapp me-2"></i>&nbsp;&nbsp;Contact Us
        </a>
      </div>
    </div>
  );
}
