import React from 'react'
import { Link } from 'react-router-dom'

export default function Error() {
    return (
        <div className="my-5 py-5 text-center">
            <h1 className=" text-danger">Error 404</h1>
            <h3 className=" text-blue">Page Not Found</h3>
            <Link to="/" className="btn btn-outline-danger">Go&nbsp;Home</Link>
        </div>
    )
}