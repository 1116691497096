import React from "react";
import "./Projects.css";
import { Container, Col, Row, Card } from "react-bootstrap";
import retroof from "../asset/img/our-product-home/ret-roof.jpg";
import img1 from "../asset/img/our-product-home/dom.jpg";
import Banner from "../home-components/CarouselBanner";
import TopBanner from "../home-components/HeaderContent.jsx";
import { Tabs, Tab } from "react-bootstrap";
import projectsComData from "../json/ProjectsCom.json";
import projectsResData from "../json/ProjectsRes.json";
import comProject1 from "../asset/img/projects/comercial/AES-New-Delhi.jpg";
import comProject2 from "../asset/img/projects/comercial/Hotel-LA-New-Delhi.jpg";
import comProject3 from "../asset/img/projects/comercial/Hotel-Poinisuk-shillong.jpg";
import comProject4 from "../asset/img/projects/comercial/Levels-Restobar-Bengaluru.jpg";
import comProject5 from "../asset/img/projects/comercial/RESERVOIR-BENGALURU.jpg";
import comProject6 from "../asset/img/projects/comercial/Sri-Venkateswara-temple-TTD-Tirumala.jpg";
import comProject7 from "../asset/img/projects/comercial/Tabularasa-Restobar-Hyderabad.jpg";
import comProject8 from "../asset/img/projects/comercial/THE-MACHAN-LONAVALA.jpg";
import comProject9 from "../asset/img/projects/comercial/Total-environment-Bengaluru-international-airport.jpg";
import comProject10 from "../asset/img/projects/comercial/Wanderer-brewery-Bengaluru.jpg";
import comProject11 from "../asset/img/projects/comercial/reliance.jpg";
import comProject12 from "../asset/img/projects/comercial/isr.jpg";

import resProject1 from "../asset/img/projects/residential/EXTENROOF-BENGALURU.jpg";
import resProject2 from "../asset/img/projects/residential/EXTENROOF-MUMBAI.jpg";
import resProject3 from "../asset/img/projects/residential/FALCON-RETRACTABLE-ROOF-HYDERABAD.jpg";
import resProject4 from "../asset/img/projects/residential/HI-POOL ENCLOSURE-SHARJAH.jpg";
import resProject5 from "../asset/img/projects/residential/HI-POOL-ENCLOSURE-ABU-DHABI.jpg";
import resProject6 from "../asset/img/projects/residential/RETRACTABLE-PATIO-ENCLOSURE-LONAVALA.jpg";
import resProject7 from "../asset/img/projects/residential/RETRACTABLE-PATIO-ENCLOSURE-NEW-DELHI.jpg";
import resProject8 from "../asset/img/projects/residential/SPECTRA-RETRACTABLE ROOF-BENGALURU.jpg";
import resProject9 from "../asset/img/projects/residential/SPECTRA-RETRACTABLE-ROOF-AHMEDABAD.jpg";
import resProject10 from "../asset/img/projects/residential/SPECTRA-RETRACTABLE-ROOF-GOA.jpg";
import pres1 from "../asset/img/projects/residential/projectRes1.jpg";
import pres3 from "../asset/img/projects/residential/projectRes3.jpg";
import pres4 from "../asset/img/projects/residential/projectRes4.jpg";
import pres5 from "../asset/img/projects/residential/projectRes5.jpg";
import pres6 from "../asset/img/projects/residential/projectRes6.jpg";
import pres10 from "../asset/img/projects/residential/projectRes10.jpg";
import pres11 from "../asset/img/projects/residential/projectRes11.jpg";
import pres12 from "../asset/img/projects/residential/projectRes12.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import { useLocation, Link} from "react-router-dom";

export default function Projects() {
  React.useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const pageName = "OUR PROJECTS";

  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      {/* <Banner /> */}
      <TopBanner sendData={pageName} />
      <Container fluid className="projects-main-page mt-sm-5 mt-2">
        <div className="d-none d-lg-block" id="projects-upper-float-div"></div>
        <Row>
          <Col id="projects-heading">
            <h4 className="text-center ft-darkk">
              Explore our wide range of products
            </h4>
            <br />
          </Col>
        </Row>
        {/* ---------------tabs----------------- */}
        <div className="container d-block">
          <Tabs defaultActiveKey="first" className="bg-white nav-tab">
            <Tab eventKey="first" title="Commercial">
              {/* --------content1---------- */}

              <Container className=" py-4 mt-3">
                <Row className="my-4 projects-cards ">
                  {projectsComData.map((data, index) => {
                    return (
                      <>
                        <Col lg={4} md={6} className="mb-2 mb-md-5" key={index}>
                          <Link to={data.project_link}>
                            <Card className="cards shadow">
                              <Card.Img
                                variant="top"
                                src={data.project_img}
                                alt="not available"
                              />
                              <Card.Body className="cards-body">
                                <Card.Title>{data.project_title}</Card.Title>
                                {/* <Card.Text>
                                                        {data.project_text}
                                                    </Card.Text> */}
                              </Card.Body>
                            </Card>
                          </Link>
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </Container>

              {/* -----------content1 end-------- */}
            </Tab>
            <Tab eventKey="second" title="Residential">
              {/* --------content2---------- */}

              <Container className=" py-4 mt-3">
                <Row className="my-4 projects-cards ">
                  {projectsResData.map((rdata, rindex) => {
                    return (
                      <>
                        <Col
                          lg={4}
                          md={6}
                          className="mb-2 mb-md-5"
                          key={rindex}
                        >
                          <Link to={rdata.project_link}>
                            <Card className="cards shadow">
                              <Card.Img
                                variant="top"
                                src={rdata.project_img}
                                alt="not available"
                              />
                              <Card.Body className="cards-body">
                                <Card.Title>{rdata.project_title}</Card.Title>
                                <Card.Text>{rdata.project_text}</Card.Text>
                              </Card.Body>
                            </Card>
                          </Link>
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </Container>

              {/* -----------content2 end-------- */}
            </Tab>
          </Tabs>
        </div>
        {/* -------------tabs end-------------------- */}

        <div className="d-none d-lg-block" id="projects-lower-float-div"></div>
      </Container>
    </div>
  );
}
