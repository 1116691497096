import React from "react";
import "./ShortAboutMega.css";
import { Container, Col, Row } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
export default function ShortAboutMega() {
  React.useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div>
      <Container fluid id="short-about" data-aos="fade-up">
        <div id="inner"></div>
        <Container className="content  my-5">
          <Row>
            <Col lg={6} id="inner-upper" data-aos="fade-bottom">
              <iframe
                id="iframe"
                src="https://www.youtube.com/embed/21kYDWN_Udk?autoplay=1&mute=1&color=white&rel=0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Col>
            <Col
              lg={6}
              className="mt-md-0 mt-3"
              id="about-megavent"
              data-aos="fade-bottom"
            >
              <h1 className="text-til">ABOUT MEGAVENT</h1>
              <p>
                Megavent has evolved as a well known brand today as a result of
                consistent efforts in innovation, dedicated solutions and
                services provided to our esteemed customers over the years.
              </p>
              <ul id="shortAbout-li" data-aos="fade-bottom">
                <li>
                  <span
                    className="iconify"
                    data-icon="akar-icons:double-check"
                    data-inline="false"
                  ></span>
                  Megavent is proud to be the pioneers in introducing
                  Retractable roof concept in India.
                </li>
                <li>
                  <span
                    className="iconify"
                    data-icon="akar-icons:double-check"
                    data-inline="false"
                  ></span>
                  We at Megavent understand the customer needs, develop , build
                  and provide a complete customised solution for their
                  requirement.
                </li>
                <li>
                  <span
                    className="iconify"
                    data-icon="akar-icons:double-check"
                    data-inline="false"
                  ></span>
                  We have more than 600 happy customers for our Retractable roof
                  solutions spread all over India, USA, UK, Canada and UAE which
                  is steadily increasing.
                </li>
              </ul>
              <div
                className="d-flex justify-content-center "
                data-aos="fade-up"
              >
                <Link
                  to="/about-us"
                  className="btn btn-md-lg  px-md-5 py-md-3"
                  id="know-more"
                >
                  Know More <b>&gt;</b>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
