import React from "react";
import "./QualitySystem.css";
import icon1 from "../asset/img/home/Icons-01.png";
import icon2 from "../asset/img/home/Icons-02.png";
import icon3 from "../asset/img/home/Icons-03.png";
import quality from "../asset/img/aboutus/certificate.jpeg";

import { Container, Col, Row } from "react-bootstrap";
export default function QualitySystem() {
  return (
    <div>
      <Container fluid className="py-5  mt-2 mt-md-5" id="quality-aboutus">
        <div className="d-none d-md-block" id="quality-float-div"></div>
        <Col>

          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="cards">
                  <h1 className="py-1" id="quality-head">QUALITY&nbsp;SYSTEM</h1>
                  <h4 className="text-center ft-darkk">
                    Quality to Improve our Products & Services
                  </h4>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card" id="quality-sys">
                  <img src={quality} alt="Not available" />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Container>
      <Container fluid id="quality-back-img">
        <Container fluid className="quality-content py-5 mb-2 mb-md-5">
          <Container className="quality-content-cards py-5">
            <Row className=" justify-content-center text-center">
              <Col md={3} className="m-2 m-md-4 quality-content">
                <img src={icon3} alt="" />
                <h4>100% Satisfaction</h4>
                <p>
                  We believe in continuous development of technology by upgrading
                  the product design as well as production process to deliver
                  high quality products.
                </p>
              </Col>
              <Col md={3} className="m-2 m-md-4 quality-content">
                <img src={icon2} alt="" />
                <h4>Guarantee</h4>
                <p>
                  We use the best quality materials for our products for
                  longevity of our product.
                </p>
              </Col>
              <Col md={3} className="m-2 m-md-4 quality-content">
                <img src={icon1} alt="" />
                <h4>100% Safe</h4>
                <p>
                  Stringent quality procedures and checks ensure that the
                  product meets the desired design parameters.
                </p>
              </Col>
            </Row>
          </Container>
        </Container>
      </Container>
    </div>
  );
}
