import React from "react";
import { Container, Col, Row } from "react-bootstrap";
// import { Link } from "react-router-dom";
import "./ShortAboutUs.css";
import roof from "../asset/img/aboutus/about.jpg";
import downloadPdf from "../asset/megavent_product_catalouge.pdf";

export default function ShortAboutUs() {
  return (
    <div>
      <Container fluid className=" py-3" id="aboutus-container-fluid">
        <div className="d-none d-sm-block" id="about-float-div"></div>
        <Container>
          <Row className="short-aboutus my-5 align-items-center">
            <Col
              lg={7}
              className="p-2 p-md-5 shadow order-lg-0 order-1 "
              id="short-aboutus-left"
            >
              <h2 className="">ABOUT US</h2>
              <br />
              <p>
                Megavent Technologies Pvt Ltd was established in the year 1995
                to manufacture engineering products for precision machineries .
                Later on it diversified into specialized field of retractable
                roofs.
                <br />A professionally well managed company with a very
                competent design team, highly skilled production team, well
                trained installation team and onsite support team is in place to
                serve our customers to the highest satisfaction. Megavent
                manages the enterprise sales by creating and supporting the
                channel partner network in designated territories to provide
                immediate and effective support to our customers.
              </p>
              <p>
                We have our products installed all over India .Our products are
                being exported to the UK, USA, UAE and CANADA.
              </p>
              <p>
                Experienced design team and a highly skilled production team
                bring out innovation and quality products & service which are
                amongst the best in the world and at competitive prices .
              </p>

              <div
                className="d-flex justify-content-lg-end justify-content-center "
                id="down-cat-div"
              >
                <a
                  className="btn btn-md-lg   px-md-4 py-md-3"
                  id="down-cat"
                  download
                  href={downloadPdf}
                >
                  Download Catalogue
                </a>
              </div>
            </Col>
            <Col
              lg={5}
              id="short-about-img"
              className="order-lg-1 order-0 mb-2"
            >
              <img
                src={roof}
                alt="Not available"
                className="shadow costomeImage"
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
