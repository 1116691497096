import React from "react";
import "./Footer.css";
import logo from "./asset/img/home/logo.png";

import { Link } from "react-router-dom";
// import Aos from "aos";
// import "aos/dist/aos.css";
export default function Footer() {
  // React.useEffect(() => {
  //   Aos.init({ duration: 1000 });
  // }, []);
  return (
    <>
      <footer className="text-white pt-5 bg-darkk  pb-1" id="footer-mainDiv">
        <div className="container">
          <div className="row justify-content-between" id="footer-content">
            <div className="col-lg-3 col-md-6 col-sm-12 pb-md-5 pb-2 ">
              <div className="mb-2">
                <img src={logo} alt="not available" />
              </div>
              <p>
                #119/1, Forest Gate,
                <br />
                Machohalli, Dasanapura Hobli,
                <br />
                Magadi Main Road,
                <br />
                Bangalore 560091
              </p>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-6 pb-md-5 pb-2"
              id="footer-column2"
            >
              <h5 className="mx-4 mb-sm-4 text-uppercase">Useful Links</h5>
              <ul>
                <li>
                  <span
                    className="iconify"
                    data-icon="grommet-icons:form-next"
                    data-inline="false"
                  ></span>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <span
                    className="iconify"
                    data-icon="grommet-icons:form-next"
                    data-inline="false"
                  ></span>
                  <Link to="/about-us">About us</Link>
                </li>
                <li>
                  <span
                    className="iconify"
                    data-icon="grommet-icons:form-next"
                    data-inline="false"
                  ></span>
                  <Link to="/projects">Projects</Link>
                </li>
                <li>
                  <span
                    className="iconify"
                    data-icon="grommet-icons:form-next"
                    data-inline="false"
                  ></span>
                  <Link to="/gallery/images">Gallery</Link>
                </li>
                <li>
                  <span
                    className="iconify"
                    data-icon="grommet-icons:form-next"
                    data-inline="false"
                  ></span>
                  <Link to="/blogs">Blogs</Link>
                </li>
                <li>
                  <span
                    className="iconify"
                    data-icon="grommet-icons:form-next"
                    data-inline="false"
                  ></span>
                  <Link to="/contact">Contacts</Link>
                </li>
              </ul>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-6 pb-md-5 pb-2"
              id="footer-column3"
            >
              <Link to="/products">
                <h5 className="mx-4 mb-sm-4 text-uppercase">Products</h5>
              </Link>
              <ul>
                <li>
                  <span
                    className="iconify"
                    data-icon="grommet-icons:form-next"
                    data-inline="false"
                  ></span>
                  <Link to="/products/product/1">Retractable Roof</Link>
                </li>

                <li>
                  <span
                    className="iconify"
                    data-icon="grommet-icons:form-next"
                    data-inline="false"
                  ></span>
                  <Link to="/products/product/2">
                    Retractable Patio Enclosure
                  </Link>
                </li>
                <li>
                  <span
                    className="iconify"
                    data-icon="grommet-icons:form-next"
                    data-inline="false"
                  ></span>
                  <Link to="/products/product/4">
                    Telescopic Pool Enclosure
                  </Link>
                </li>
                <li>
                  <span
                    className="iconify"
                    data-icon="grommet-icons:form-next"
                    data-inline="false"
                  ></span>
                  <Link to="/products/product/6">Fixed Skylight</Link>
                </li>
                <li>
                  <span
                    className="iconify"
                    data-icon="grommet-icons:form-next"
                    data-inline="false"
                  ></span>
                  <Link to="/products/product/3">Dome Enclosure</Link>
                </li>
                <li>
                  <span
                    className="iconify"
                    data-icon="grommet-icons:form-next"
                    data-inline="false"
                  ></span>
                  <Link to="/products/product/5">Heavy Duty Umbrella</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 pb-5">
              <h5 className="mx-4 mb-sm-4 text-uppercase">megavent</h5>
              <div className="number d-flex">
                <div className="name-number">Phone:- </div>
                <div className="number-content ml-3 mr-5">
                  <p>
                    +91&nbsp;9972963229 <br /> +91&nbsp;9342161305
                  </p>
                </div>
              </div>
              <p>Email: info@megavent.in</p>
              <div>
                <Link to="">
                  <span
                    className="iconify mx-1"
                    data-icon="fa:twitter-square"
                    data-inline="false"
                  ></span>
                </Link>
                <Link to="">
                  <span
                    className="iconify mx-1"
                    data-icon="entypo-social:facebook"
                    data-inline="false"
                  ></span>
                </Link>

                <a href="https://www.youtube.com/channel/UCUQNyeYNbHPGKCl5sGH80FQ">
                  <span
                    className="iconify mx-1"
                    data-icon="entypo-social:youtube"
                    data-inline="false"
                  ></span>
                </a>

                <Link to="">
                  <span
                    className="iconify mx-1"
                    data-icon="fa-brands:instagram-square"
                    data-inline="false"
                  ></span>
                </Link>
                <Link to="">
                  <span
                    className="iconify mx-1"
                    data-icon="brandico:skype"
                    data-inline="false"
                  ></span>
                </Link>
                <Link to="">
                  <span
                    className="iconify mx-1"
                    data-icon="brandico:linkedin-rect"
                    data-inline="false"
                  ></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid  py-3 bg-darkk text-center"
          id="hr-line"
        >
          <p> &#169; Copyright Megavent 2021. All Rights Reserved</p>
        </div>
      </footer>
    </>
  );
}
