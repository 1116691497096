import React from "react";
import Banner from "./home-components/CarouselBanner";
import ShortAboutUs from "./home-components/ShortAboutUs";
import VisionMission from "./home-components/visionMission";
import Management from "./about-us-components/Management";
import Quality from "./about-us-components/QualitySystem";
import FaqHome from "./home-components/FaqHome";
import TopBanner from "./home-components/HeaderContent.jsx";
import { useLocation } from "react-router-dom";

export default function AboutUs() {
  // useLayoutEffect(() => {
  //     window.scrollTo(0, 0)
  // });
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const pageName = "ABOUT US";
  return (
    <div>
      {/* <Banner/> */}
      <TopBanner sendData={pageName} />
      <ShortAboutUs />
      <VisionMission />
      <Management />
      <Quality />
      <FaqHome />
    </div>
  );
}
