import React from "react";
import { Link } from "react-router-dom";
const HeaderContent = (props) => {
  return (
    <div>
      <div className="banner-heading-gradient">
        {/* <h1 className="text-center text-white"><Link to="/" style={{textDecoration:"none"}}>MEGAVENT</Link></h1> */}
        <h2 className="text-center text-white">{props.sendData}</h2>
      </div>
    </div>
  );
};

export default HeaderContent;
