import React from 'react'
import "./Blogs.css"
import { Container, Row, Col } from 'react-bootstrap'
import Banner from "../home-components/CarouselBanner";
import TopBanner from "../home-components/HeaderContent.jsx";
import { useLocation } from "react-router-dom";
export default function Blogs() {
    const pageName = "Blogs"

    const location = useLocation();
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
    return (
        <div className="bg">
            {/* <Banner/> */}
            <TopBanner sendData={pageName} />
            <Container id="our-blogs">
                <Row>
                    <Col>
                        <h1 className="text-center">OUR BLOGS</h1><br />
                        <div class="container">
                            <div class="row">
                                <div class="col-md-4 col-sm-6 mb-3">
                                    <div class="serviceBox">
                                        <div class="service-content">
                                            <div class="service-icon">
                                                <span><i class="fa fa-globe"></i></span>
                                            </div>
                                            <h3 class="title">Blog</h3>
                                            <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui quaerat fugit quas veniam perferendis repudiandae sequi, dolore quisquam illum.</p>
                                            <a href="/" class="read-more">read more</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 mb-3">
                                    <div class="serviceBox">
                                        <div class="service-content">
                                            <div class="service-icon">
                                                <span><i class="fa fa-globe"></i></span>
                                            </div>
                                            <h3 class="title">Blog</h3>
                                            <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui quaerat fugit quas veniam perferendis repudiandae sequi, dolore quisquam illum.</p>
                                            <a href="/" class="read-more">read more</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 mb-3">
                                    <div class="serviceBox">
                                        <div class="service-content">
                                            <div class="service-icon">
                                                <span><i class="fa fa-globe"></i></span>
                                            </div>
                                            <h3 class="title">Blog</h3>
                                            <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui quaerat fugit quas veniam perferendis repudiandae sequi, dolore quisquam illum.</p>
                                            <a href="/" class="read-more">read more</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 mb-3">
                                    <div class="serviceBox">
                                        <div class="service-content">
                                            <div class="service-icon">
                                                <span><i class="fa fa-globe"></i></span>
                                            </div>
                                            <h3 class="title">Blog</h3>
                                            <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui quaerat fugit quas veniam perferendis repudiandae sequi, dolore quisquam illum.</p>
                                            <a href="/" class="read-more">read more</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 mb-3">
                                    <div class="serviceBox">
                                        <div class="service-content">
                                            <div class="service-icon">
                                                <span><i class="fa fa-globe"></i></span>
                                            </div>
                                            <h3 class="title">Blog</h3>
                                            <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui quaerat fugit quas veniam perferendis repudiandae sequi, dolore quisquam illum.</p>
                                            <a href="/" class="read-more">read more</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 mb-3">
                                    <div class="serviceBox">
                                        <div class="service-content">
                                            <div class="service-icon">
                                                <span><i class="fa fa-globe"></i></span>
                                            </div>
                                            <h3 class="title">Blog</h3>
                                            <p class="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui quaerat fugit quas veniam perferendis repudiandae sequi, dolore quisquam illum.</p>
                                            <a href="/" class="read-more">read more</a>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>

        </div>
    )
}
